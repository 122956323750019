.BottomFooterBlock {
    height: 49px;
    padding-top: 12px;
    border-top: 1px solid #e4e4e4;

    .copyright {
        width: 228px;
        height: 24px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: 0.25px;
        text-align: center;
        color: #353535;
    }
}

.PhoneInputCountrySelect, .PhoneInputCountryIcon  {
    display: none;
}