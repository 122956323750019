.News {
    .header {
        padding-top: 20px;
        padding-bottom: 23px;
        background-color: #f1f6f9;
    }
    .content {
        font-family: Montserrat;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: 0.71px;
        color: #353535;
        text-align: left;
        .title {
            font-size: 32px;
            font-weight: bold;
        }
    }

    .banner {
        .image {
            max-width: 100%;
            max-height: 500px;
        }
        margin-bottom: 40px;
        max-height: 500px;
        overflow: hidden;
    }

    .body {
        display: flex;
        .leftBlock {
            width: 674px;
            .post {
                margin-bottom: 40px;
                text-align: left;
                .image {
                    width: 674px;
                    margin-bottom: 16px;
                }
                .title {
                    width: 674px;
                    font-family: Montserrat;
                    font-size: 28px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 0.62px;
                    color: #353535;
                    text-align: center;
                    margin-bottom: 8px;
                }
                .date {
                    text-align: left;
                    width: 165px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 2;
                    letter-spacing: 0.27px;
                    margin-bottom: 8px;
                    color: #acacac;
                }
                .content {
                    width: 674px;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: 0.25px;
                    color: #000000;
                }
                .button {
                    display: block;
                    width: 130px;
                    height: 36px;
                    padding: 6px 19px;
                    background-color: #4f7906;
                    color: #fff;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .itemsList {
            display: flex;
            justify-content: space-between;
            .NewsItem {
                width: 238px;
                font-family: Montserrat;
                text-align: left;
                color: #353535;
                .image {
                    width: 238px;
                    height: 238px;
                }
                .title {
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: 0.31px;

                }
                .description {
                    font-size: 14px;
                }
                .bottomBlock {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    color: #acacac;
                    .date {
                        font-weight: 500;
                        line-height: 2;
                        letter-spacing: 0.27px;
                    }
                    .views {
                        
                    } 
                } 
            }    
        }
        }
        .rightBlock {
            width: 238px;
            margin-left: 100px;
            .PromotionItem {
                h1, h3, p {
                    font-family: Montserrat;
                    color: #353535;
                    text-align: left;
                }
                .imageBlock {
                    h1.name {
                        text-align: center;
                        font-family: "Myriad Pro Condensed";
                        font-size: 2rem;
                        color: #46c215;
                    }
                    position: relative;
                    .image {
                        height: 200px;
                        width: 238px;
                        margin-bottom: 12px;
                    }
                    .bottom-banner {
                        width: 238px;
                    }
                    .discount {
                        position: absolute;
                        bottom: -3px;
                        right: 1px;
                        width: 65px;
                        height: 28px;
                        padding-left: 15px;
                        padding-top: 4px;
                        font-size: 14px;
                        font-weight: bold;
                        border-radius: 4px;
                        background-color: #eee42a;
                        color: #353535;
                    }
                }
                .name {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 12px;
                }
    
                .priceBlock {
                    text-align: left;
                    p {
                        display: inline-block;
                    }
                   .price {
                        font-size: 18px;
                        font-weight: bold;
                        margin-right: 4px;
                    }
                    .discountPrice {
                        font-size: 13px;
                        color: #acacac;
                        text-decoration: line-through solid #acacac;
                    }
                }
            }
        }
    }

    .title {
        font-family: Montserrat;
        font-size: 28px;
        font-weight: bold;
        color: #353535;
    }
    .divider {
        width: 240px;
        border: 1px solid #353535;
        margin: 20px auto 39px;
    }

    .itemsList {
        display: flex;
        justify-content: space-between;
        .NewsItem {
            width: 238px;
            font-family: Montserrat;
            text-align: left;
            color: #353535;
            .image {
                width: 238px;
                height: 238px;
            }
            .title {
                margin-top: 16px;
                font-size: 14px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.43;
                letter-spacing: 0.31px;

            }
            .description {
                font-size: 14px;
            }
            .bottomBlock {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: #acacac;
                .date {
                    font-weight: 500;
                    line-height: 2;
                    letter-spacing: 0.27px;

                }
                .views {
                    
                } 
            } 
        }    
    }
}

@media screen and (max-width: 1024px) {
    .News {
        .body {
            justify-content: space-between;
            .leftBlock {
                width: 500px;
                .post {
                    .image, .title, .content {
                        width: 500px;
                    }
                }
            }
            .rightBlock {
                margin-left: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .News {
        .body {
            .leftBlock {
                width: 100%;
                .post {
                    .image, .title, .content {
                        width: 100%;
                    }
                }
            }
            .rightBlock {
                display: none;
            }
        }
    }
}