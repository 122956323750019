.PromotionsSmallBox {
    margin-top: -40px;
    background-color: #fff;

    .PromotionsSmallList {

        .imageBlock {
            h1 {
                text-align: center;
                font-family: "Myriad Pro Condensed";
                font-size: 2.3rem;
                color: #46c215;

                @media screen and (max-width: 768px) {
                    font-size: 1.4rem;
                }
                @media screen and (max-width: 320px) {
                    font-size: .8rem;
                }
            }
        }

        .promotion-circle {

            @media screen and (max-width: 768px){
                width: 80px;
                height: 80px;
                bottom: 55px;
                right: 5px;
                border-radius: 100px;
                font-size: .9rem;
                padding-top: 30px;
            }

            @media screen and (max-width: 425px){
                width: 50px;
                height: 50px;
                bottom: 30px;
                right: 5px;
                border-radius: 100px;
                font-size: .55rem;
                padding-top: 19px;
            }

            @media screen and (max-width: 320px){
                width: 36px;
                height: 36px;
                bottom: 23px;
                right: 3px;
                border-radius: 100px;
                font-size: .3rem;
                padding-top: 14px;
            }

            position: absolute;
            display: block;
            width: 130px;
            height: 130px;
            bottom: 65px;
            right: 5px;
            border-radius: 100px;
            font-size: 1.5rem;
            font-weight: bold;
            text-shadow: 1px 1px 2px black;
            color: white;
            background-color: red;
            padding-top: 45px;
            text-align: center;
            box-shadow: 1px 1px 7px black;
        }
        .bottom-banner {
            width: 100%;
        }

        .owl-carousel {
            .owl-nav {
                button {
                    display: inline-block;
                    &:focus {
                        outline: none;
                    }
                    span {
                        display: block;
                        font-size: 51px;
                        line-height: 21px;
                        background-color: #4f7906;
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .owl-stage-outer::after {
            display: block;
            content: "";
            background-image: url("../assets/images/bottom-banner.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            height: 152px;
        }

        .slider {
            position: relative;
            height:226px;
            width:100%;
            margin-bottom: 30px;
            overflow: hidden;

            &-inner {
                position: absolute;
            }
        }

        text-align: left;
        padding: 8px;

        .PromotionItem {
            height:226px;
            width:226px;
            display: inline-block;
            cursor: pointer;
            margin: 12px;

            h1, h3, p {
                font-family: Montserrat;
                color: #353535;
                text-align: left;
            }

            .imageBlock {
                position: relative;
                .image {
                    height: 100%;
                    width: 100%;
                    margin-bottom: 12px;
                }
                .discount {
                    position: absolute;
                    bottom: -3px;
                    right: 1px;
                    width: 65px;
                    height: 28px;
                    padding-left: 15px;
                    padding-top: 4px;
                    font-size: 14px;
                    font-weight: bold;
                    border-radius: 4px;
                    background-color: #eee42a;
                    color: #353535;
                }
            }

            .name {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 12px;
            }

            .priceBlock {
                p {
                    display: inline-block;
                }
               .price {
                    font-size: 18px;
                    font-weight: bold;
                    margin-right: 4px;
                }
                .discountPrice {
                    font-size: 13px;
                    color: #acacac;
                    text-decoration: line-through solid #acacac;
                }
            }
        }

        @media screen and (max-width: 767px){
            .PromotionItem {
                width: 100%;
                margin: 0;
            }
        }

        .default-button {
            margin-left: 12px;

            span {
                margin-top: 7px;
            }
        }

        @media screen and (max-width: 767px){
            .default-button {
                margin: auto;
            }
        }

    }
}

.PromotionItem {
    .imageBlock {
        h1.name {
            text-align: center;
            font-family: "Myriad Pro Condensed";
            font-size: 2rem;
            color: #46c215;
        }
    }
}
