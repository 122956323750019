.SubscribeBlock {
    background-image: url("../assets/images/BG_2.jpg");
    background-position-y: 64%;
    padding-top: 106px;
    .title {
        font-family: Montserrat;
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.62px;
        text-align: center;
        color: #ffffff;
    }
    .description {
        margin-top: 8px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: 0.31px;
        text-align: center;
        color: #ffffff;
    }
    @media screen and (max-width: 200px) {
        .title {
            font-size: 16px;
        }
        .description {
            font-size: 13px;
        }
    }
    .SubscribeForm {
        padding-bottom: 106px;
        .input-group {
            width: 671px;
            margin: 40px auto;
            .input {
                height: 46px;
                margin-right: 20px;
            }
            .button {
                width: 172px;
                height: 46px;

                span {
                    margin: 10px 27px;
                    font-family: Montserrat;
                    font-size: 16px;    
                }
            }    
        }
    }

    @media screen and (max-width: 767px) {
        .SubscribeForm {
            .input-group {
                width: 90%;
                .input-group-append, .input {
                    width: 100%;
                }
                .input-group-append {
                    margin-top: 1rem;
                    .button {
                        margin: auto;
                    }
                }
            }
        }
    }
}