body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-group-append {
  margin-left: 0px;
}

.react-autosuggest__container {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 250px;
  margin-bottom: 0;
}

.react-autosuggest__input {
  width: 100%;
  border: 2px solid #f5a623;
  border-radius: 0;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .react-autosuggest__container {
      width: 350px;
  }
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 45px;
  width: 100%;
  text-align: left;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

@font-face {
  font-family: 'Myriad Pro Condensed';
  src: local('Myriad Pro Condensed Semibold'), local('Myriad-Pro-Condensed-Semibold'),
      url('./assets/fonts/MyriadPro-SemiboldCond.woff2') format('woff2'),
      url('./assets/fonts/MyriadPro-SemiboldCond.woff') format('woff'),
      url('./assets/fonts/MyriadPro-SemiboldCond.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}