.header {
    padding-top: 20px;
    padding-bottom: 23px;
    margin-bottom: 40px;
    background-color: #f1f6f9;
}
.content {
    font-family: Montserrat;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.71px;
    color: #353535;
    text-align: left;
    .title {
        font-size: 32px;
        font-weight: bold;
    }
    .breadCrumpb {

    }
}

.PromotionItem {
    .imageBlock {
        .promotion-circle {
            position: absolute;
            display: block;
            width: 100px;
            height: 100px;
            bottom: 63px;
            right: 5px;
            border-radius: 100px;
            font-size: 1.2rem;
            font-weight: bold;
            text-shadow: 1px 1px 2px black;
            color: white;
            background-color: red;
            padding-top: 34px;
            text-align: center;
            box-shadow: 1px 1px 7px black;
        }
    }
}

.promotions {

    text-align: center;
    padding: 8px;

    .bottom-banner {
        width: 100%;
        margin-top: 50px;
    }

    .PromotionItem {
        position: relative;
        height: 284px;
        margin-bottom: 24px;
        display: inline-block;
        cursor: pointer;

        h1, h3, p {
            font-family: Montserrat;
            color: #353535;
            text-align: left;
        }

        .imageBlock {
            position: relative;
            .image {
                max-height: 208px;
                max-width: 100%;
                margin-bottom: 12px;
            }
            .discount {
                position: absolute;
                bottom: -3px;
                right: 1px;
                width: 65px;
                height: 28px;
                padding-left: 15px;
                padding-top: 4px;
                font-size: 14px;
                font-weight: bold;
                border-radius: 4px;
                background-color: #eee42a;
                color: #353535;
            }
        }

        .name {
            font-size: 14px;
            font-weight: 600;
        }

        .priceBlock {
            p {
                display: inline-block;
            }
           .price {
                font-size: 18px;
                font-weight: bold;
                margin-right: 4px;
            }
            .discountPrice {
                font-size: 13px;
                color: #acacac;
                text-decoration: line-through solid #acacac;
            }
        }

        
    }

    .default-button {
        margin-left: 12px;

        span {
            margin-top: 7px;
        }
    }
}

.PromotionBigItem {
    position: relative;
    display: flex;
    margin-top: 50px;
    background-color: #f1f6f9;

    .contentBlock {
        width: 547px;
        margin-top: 29px;
        margin-left: 40px;
        .name {
            font-family: Montserrat;
            font-size: 28px;
            font-weight: 600;
            text-align: left;
            color: #353535;
        }
        .description {
            margin-top: 16px;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.43;
            text-align: left;
            color: #353535;
        }
        .default-button {
            width: 218px;
            margin-top: 16px;
            margin-bottom: 29px;
            i {
                color: #fff;
                margin: 9px 3px;
            }
        }
    }

    @media screen and (max-width: 767px){
        .contentBlock {
            width: 100%;
            margin-left: 0;
        }
    }

    @media screen and (max-width: 285px) {
        .contentBlock {
            .default-button {
                width: 100%;
            }
        }
    }

    .imageBlock {
        position: absolute;
        bottom: 0;
        right: 50px;
        margin-left: 93px;
        img {
            width: 294px;
            height: 326px;
        }
    }
}