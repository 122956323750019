.TopNav {
    background-image: url("/assets/images/BG.jpg");
    background-position-y: bottom;

    .nav-item {
        &.dropdown {
            a.nav-link {
                display: contents;
            }
            a.facebook-link {
                margin-left: 11px;
            }
        }
        &.cart {
            background-color: #daf58e;
            a, i {
                color:#000;
                &:hover, &:focus {
                    color:#222;
                }
            }
        }
    }
    .navbar-nav.navbar-mini {
        flex-direction: row;
        .nav-item {
            // min-width: 30px;
        }
    }

    .navbar-nav {
        .dropdown-menu {
            position: absolute;
        }
    }
}