.warning {
    // height:220px;
    padding-top: 117px;
    background-color: #f1f6f9;

    h3 {
        margin-bottom: 0;
    }
}
.BigSearchBlock {
    min-height:420px;
    padding-top: 86px;
    padding-bottom: 60px;
    background-color: #f1f6f9;

    h1, h3 {
        text-align: left;
        font-family: Montserrat;
        font-style: normal;
        font-stretch: normal;
        color: #353535;
    }

    h1 {
        min-height: 30px;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.25;
    }

    h3 {
        min-height: 19px;
        margin-bottom: 39px;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
    }

    .standart-container.info {
        h1 {
            text-align: center;
        }
    }

    @media screen and (max-width: 200px) {
        .standart-container {
            h1 {
                font-size: 20px;
            }
            h3 {
                font-size: 13px;
            }
        }
    }

    .SearchFormHome {
        .form-control {
            width: 250px;
            max-width: 100%;
            border: 2px solid #f5a623;
            border-radius: 0;
        }
        @media screen and (max-width: 767px) {
            .form-control {
                width: 350px;
            }
        }
        @media screen and (max-width: 285px) {
            .form-control {
                width: 100%;
            }
        }
        @media screen and (max-width: 200px) {
            .form-control,
            .custom-select {
                height: 50%;
                font-size: 13px;
            }
        }
        .custom-select {
            max-width: 100%;
            width: 350px;
            margin-left: 0;
            border: 2px solid #f5a623;
        }
        @media screen and (max-width: 350px) {
            .custom-select {
                width: 100%;
            }
        }
        .default-button {
            width: 136px;
            height: 46px;
            border: 2px solid #f5a623;

            span {
                font-family: Montserrat;
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.29px;
                text-align: center;
                color: #ffffff;

                margin: 13px 30px;
            }
        }
    }
}