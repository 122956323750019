.InfoLayer {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top:0;
    z-index: 10;
    .wrapper {
        .block {
            position: relative;
            width: 849px;
            max-width: 100%;
            min-height: 70px;
            margin: calc(20% - 35px) auto 0;
            padding: 20px;
            box-shadow: 0 2px 15px 0 rgba(53, 53, 53, 0.3);
            background-color: #eee42a;
            .text {
                margin: 0;
                font-family: Montserrat;
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.88;
                letter-spacing: 0.18px;
                text-align: center;
                color: #353535;
            }

            .close-botton {
                position: absolute;
                right: 10px;
                top: 5px;
                cursor: pointer;
            }
        }    
    }
    
}