.ForClients {
    .header {
        text-align: left;
        padding-top: 20px;
        padding-bottom: 23px;
        margin-bottom: 40px;
        background-color: #f1f6f9;
    }
    .body {
        .title {
            font-family: Montserrat;
            font-size: 28px;
            font-weight: bold;
            color: #353535;
        }
        .divider {
            width: 240px;
            border: 1px solid #353535;
            margin: 20px auto 39px;
        }
        .text {
            width: 674px;
            text-align: justify;
            margin: auto;
        }
    }
}
@media screen and (max-width: 767px) {
    .ForClients {
        .body {
            .text {
                width: 100%;
            }
        }
    }
}