.NewsBlock {
    margin-top: 60px;

    .NewsList {
        margin-top: 100px;
        margin-bottom: 90px;

        .title {
            font-family: Montserrat;
            font-size: 28px;
            font-weight: bold;
            color: #353535;
        }
        @media screen and (max-width: 200px) {
            .title {
                font-size: 16px;
            }
        }
        .divider {
            max-width: 240px;
            border: 1px solid #353535;
            margin: 20px auto 39px;
        }

        .itemsList {
            display: flex;
            justify-content: space-between;
            .NewsItem {
                // max-width: 238px;
                font-family: Montserrat;
                text-align: left;
                color: #353535;
                cursor: pointer;
                .image {
                    width: inherit;
                    // max-width: 208px;
                    // height: 238px;
                }
                .title {
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: 0.31px;

                }
                .description {
                    font-size: 14px;
                }
                .bottomBlock {
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    color: #acacac;
                    .date {
                        font-weight: 500;
                        line-height: 2;
                        letter-spacing: 0.27px;

                    }
                    .views {
                        
                    } 
                } 
            }    
        }
    }
}