.CarouselBlock {
    padding-top: 60px;
    background-color: #f1f6f9;
    .title {
        font-family: Montserrat;
        font-size: 28px;
        font-weight: bold;
        color: #353535;
    }
    .divider {
        width: 240px;
        border: 1px solid #353535;
        margin: 20px auto 39px;
    }
    .itemsList {
        .CarouselItem {

        }
    }
}