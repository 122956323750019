.SearchBlock {
    
    .SearchForm {
        background-color: #f1f6f9;
        padding-top: 20px;
        padding-bottom: 23px;
        margin-bottom: 40px;
        .content {
            .title {
                margin-bottom: 20px;
                font-family: Montserrat;
                font-size: 32px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.25;
                letter-spacing: 0.71px;
                color: #353535;
                text-align: left;
            }

            .input-group {
                input, select, button {
                    height: 46px;
                }
            }
            
            .form-control {
                width: 250px;
                border: 2px solid #f5a623;
                border-radius: 0;
            }
            
            @media screen and (max-width: 767px) {
                .form-control {
                    width: 350px;
                }
            }

            .custom-select {
                max-width: 100%;
                width: 350px;
                border: 2px solid #f5a623;
                margin-left: 0;
            }
            @media screen and (max-width: 350px) {
                .custom-select {
                    width: 100%;
                }
            }
            .default-button {
                width: 136px;
                height: 46px;
                border: 2px solid #f5a623;
    
                span {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 0.29px;
                    text-align: center;
                    color: #ffffff;
    
                    margin: 13px 30px;
                }
            }
        }
    }
    .table {
        text-align: left;
        box-shadow: 0 2px 4px 2px rgba(53, 53, 53, 0.11);

        .addToCart {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: 0.25px;
            text-align: right;
            color: #1c350c;
        }
        .old-price {
            text-decoration: line-through;
        }
        .new-price {
            color: #f33;
            font-size: .75rem;
        }

        @media screen and (max-width: 767px) {
            font-size: .75rem;
            .addToCart {
                font-size: .75rem;
            }
        }
        @media screen and (max-width: 425px) {
            font-size: .5rem;
            .addToCart {
                font-size: .5rem;
            }
            td {
                padding: .4rem;
            }
        }
    }
}