.Profile {
    margin-bottom: 100px;
    .header {
        padding-top: 20px;
        padding-bottom: 23px;
        margin-bottom: 40px;
        background-color: #f1f6f9;
    }
    .content {
        font-family: Montserrat;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: 0.71px;
        color: #353535;
        text-align: left;
        .title {
            font-size: 32px;
            font-weight: bold;
        }
        .breadCrumpb {

        }
    }

    .body {
        display: flex;
        .leftSidebar {
            // width: 238px;
            // margin-right: 27px;

            .userPhoto {
                img {
                    // width: 238px;
                    width: 100%;
                }
            }
        }
        @media screen and (max-width: 767px) {
            .leftSidebar {
                .userPhoto {
                    img {
                        width: initial;
                        max-height: 250px;
                    }
                }
            }
        }
        .rightSidebar {
            // width: 759px;

            .userBio {
                margin-bottom: 41px;
                padding: 20px;
                box-shadow: 0 2px 4px 2px rgba(53, 53, 53, 0.11);

                font-family: Montserrat;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.62px;
                color: #353535;
                text-align: left;

                .userName {
                    font-size: 28px;
                    font-weight: bold;
                }
                .userEmail {
                    font-size: 14px;
                }
                .userCell {
                    font-size: 14px;
                }
            }
            .cartOrders {
                .info {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 24px;
                    .text {
                        font-family: Montserrat;
                        font-size: 24px;
                        font-weight: 300;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.5;
                        letter-spacing: 0.43px;
                        color: #000000;
                    }
                    .button {
                        span {
                            margin: 5px auto;
                        }
                    }
                }

                .ordersList {
                    margin-bottom: 60px;
                    .table {
                        tbody {
                            box-shadow: 0 2px 4px 2px rgba(53, 53, 53, 0.11);
                            .td-buttons {
                                padding: .75rem 0;
                            }

                            .button {
                                display: inline-block;
                                width: 25px;
                                height: 24px;
                                margin: 0 3px;
                                border-radius: 4px;
                                box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.07), 0 2px 2px 0 rgba(0, 0, 0, 0.07);
                                background-color: #ffffff;
                                cursor: pointer;
                                text-align: center;
                            }

                            @media screen and (max-width: 425px) {
                                .button {
                                    width: 20px;
                                    height: 20px;
                                    margin: 0;
                                }
                            }

                            @media screen and (max-width: 320px) {
                                .button {
                                    width: 15px;
                                    height: 15px;
                                    margin: 0;
                                }
                            }

                            .delete-icon {
                                img {
                                    width: 25px;
                                }
                                
                            }

                            .old-price {
                                text-decoration: line-through;
                            }
                            .new-price {
                                color: #f33;
                                font-size: .75rem;
                            }
                        }
                    }
                }

                @media screen and (max-width: 425px) {
                    .table {
                        font-size: .7rem;
                    }
                    .info .text {
                        font-size: 1rem;
                    }
                    
                }

                @media screen and (max-width: 320px) {
                    .table {
                        font-size: .4rem;
                    }
                    .info .text {
                        font-size: .8rem;
                    }
                    
                }
            }
        }

        .menu {
            margin-top: 16px;
            // padding: 5px 0;
            box-shadow: 0 2px 4px 2px rgba(53, 53, 53, 0.11);
            background-color: #ffffff;
            ul {
                padding: 0;
                margin-left: -2px;
                li.menu-item {
                    height: 36px;
                    width: 100%;
                    padding-left: 20px;
                    padding-top: 6px;
                    list-style: none;
                    text-align: left;
                    cursor: pointer;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 0.25px;
                    color: #1c350c;
                    a {
                        color: #1c350c;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            
        }

        .Settings {
            .general, .privacy {
                .settings-nav {
                    display: flex;
                    justify-content: space-between;
                    .settings-header {
                        margin-bottom: 23px;
                        font-family: Montserrat;
                        font-size: 24px;
                        font-weight: 500;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.5;
                        letter-spacing: 0.43px;
                        color: #000000;
                    }
                    .settings-button {
                        width: 171px;
                        height: 36px;
                        padding: 0 54px;
                        background-color: #4f7906;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: bold;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.71;
                        letter-spacing: 0.25px;
                        text-align: center;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .bd-callout {
        border: 1px solid #fff;
        border-left-color: #93b13f;
        border-left-width: .25rem;
        border-radius: .25rem;
    }
}

.settings-body {
    form {
        label {
            text-align: left;
            color: #5e6c84;
        }
    }
    padding: 20px;
    margin-bottom: 40px;
    box-shadow: 0 2px 4px 2px rgba(53, 53, 53, 0.11);
    border: solid 2px #f1f6f9;
    background-color: #ffffff;

    .PhoneInput {
        input {
            max-width: 100%;
        }
    }
}