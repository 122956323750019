.FooterBlock {
    padding: 60px 0;

    .row.justify-content-center {
        margin: 0;
        padding: 0;
    }

    .footerHeader {
        margin-bottom: 20px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 3;
        letter-spacing: 0.36px;
        text-align: left;
        color: #353535;
    }

    .FooterMenu {
        .Logo {
            margin-bottom: 20px;
            text-align: left;
            img {
                width: 220px;
                max-width: 100%;
                padding: .2rem 1rem;
                // height: 44px; 
            }
            @media screen and (max-width:767px) {
                text-align: center;
            }
            @media screen and (max-width:250px) {
                img {
                    width: 100%;
                    height: initial;
                }
            }
        }
        .menu {
            @media screen and (max-width:767px) {
                .menuItem {
                    .menuLink {
                        text-align: center;
                    }
                }
            }
            .menuItem {
                font-family: Montserrat;
                font-size: 14px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.71;
                letter-spacing: 0.25px;
                text-align: left;
                .menuLink {
                    color: #1c350c;
                    padding: .2rem 1rem;
                    &:hover {
                        color: #346316;
                    }
                }
            }
        }
    }
    .FooterContacts {
        @media screen and (max-width:767px) {
            .footerHeader, .contacts li {
                text-align: center;
            }
        }
        .footerHeader {
            // margin-bottom: 40px;
        }
        .contacts {
            list-style: none;
            text-align: left;
            padding-left: 0;
            li {
                margin-bottom: 8px;
            }
        }
    }
    .FooterSocial {
        .social {
            text-align: left;
            a {
                margin-right: 6px;
            }
        }
        @media screen and (max-width:767px) {
            h2, .social {
                text-align: center;
            }
        }
    }
    .FooterBackForm {
        .form {
            text-align: right;
            input {
                // width: 278px;
                width: 100%;
                height: 37px;
                margin-bottom: 12px;
            }
            textarea {
                // width: 278px;
                width: 100%;
                margin-bottom: 12px;
            }

            button {
                height: 34px;
                width: 131px;
            }
        }
        @media screen and (max-width:767px) {
            .form {
                width: 250px;
                text-align: center;
                display: inline-flex;
                flex-direction: column;
            }
            .footerHeader {
                text-align: center;
            }
        }

        @media screen and (max-width:350px) {
            .form {
                width: 100%;
            }
        }
    }
}