.MapTemplate {
    .MapPoint {
        position: relative;
        min-height: 50px;
        .map-marker {
            position: absolute;
            top: -32px;
            left: -12px;
            color: rgb(218, 52, 11);
            font-size: 32px;
        }
        .map-pointer-logo {
            width: 100px;
            margin-left: -50px;
        }    
    }
    .MapItemTemplate {
        margin-bottom: 24px;
        // text-align: left;49.825823, 24.073076
        img {
            max-width: 90%;
            // max-height: 200px;
        }
        p {
            max-width: 90%;
            text-align: left;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: 0.31px;
            color: #acacac;
            &.map-name {
                color: #353535;
                font-size: 14px;
            }
        }
    }
}