.PaginatorTemplate {
    .button {
        display: inline-block;
        width: 40px;
        height: 24px;
        margin: 0 3px;
        border: solid 1px #dfe3e9;
        background-color: #f1f6f9;

        cursor: pointer;
        &.active {
            border-radius: 4px;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.07), 0 2px 2px 0 rgba(0, 0, 0, 0.07);
            background-color: #ffffff;
        }
    }
}