.MainMenuSimple {
    padding-top: 30px;
    .navbar-brand {
        padding-bottom: 30px;
        img {
            width: 300px;
            height: 60px;
        }
    }
    .navbar-hiden-logo {
        border: none;
        img {
            width: 140px;
        }
    }
    .menu {
        // margin: 0;
        // border-top: 1px solid #e4e4e4;

        .navbarNav {
            padding-bottom: 16px;
        }

        .nav {
            .nav-item {
                margin: 0 10px;
                .nav-link {
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.71;
                    letter-spacing: 0.25px;
                    color: #1c350c;

                    &:hover {
                        color:#93b13f;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .navbar-brand {
            display: none;
        }
    }
}